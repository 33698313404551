import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

function App() {
  
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);  

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_snh1m21', 'template_l00p30r', form.current, 'ZvDniMpAbNCZDK8kh')
      .then((result) => {
          console.log(result.text);
          Toastify({
            text: "Mensaje fue enviado",
            className: "toasty",
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            }
          }).showToast();
      }, (error) => {
          console.log(error.text);
      });
  }; 

  return (
    <div className="home-style-2">
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Preloader */}
        <div className="preloader">
          <div className="preloader-inner">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* End Preloader */}

        {/* Header */}
        <header className="site-header header-style-2">
          <nav className="navigation navba navbar-default">
            <div className="container">
              <div className="navbar-header">
                <button type="button" className="open-btn">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>
              <div className="col col-md-6 logo-holder logoHeader">
                <a href="index.html">
                  <img
                    src="images/logo-2.png"
                    alt="Logo Gianelli SA, Metalúrgica, Zona Oeste"
                    className="logo-size"
                  />
                </a>
              </div>
              <div
                id="navbar"
                className="navbar-collapse collapse navigation-holder navDiv"
              >
                <button className="close-navbar">
                  <i className="fa fa-close"></i>
                </button>

                <ul className="nav navbar-nav col col-md-6">
                  <li className="current-menu-ancestor current-menu-parent">
                    <a href="#home">Inicio</a>
                  </li>
                  <li>
                    <a href="#nosotros">Nosotros</a>
                  </li>
                  <li>
                    <a href="#productos">Productos</a>
                  </li>
                  <li>
                    <a href="#contacto">Contacto</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {/* End Header */}

        {/* Hero */}
        <section id="home" className="hero hero-slider-wrapper hero-slider-s2">
          <div className="hero-slider">
            <div className="slide">
              <img
                src="images/slider/slide-11.jpg"
                alt="Frente de nuestra empresa"
                className="slider-bg"
              />
              <div className="container">
                <div className="row slide-caption">
                  <div className="col col-md-8">
                    <h1 className="slide-title">
                      Somos una empresa especializada en <span>inyección</span>{" "}
                      a presión de aleaciones de <span>aluminio.</span>
                    </h1>
                    <p>
                      En nuestra planta de 1500m2, contamos con 9 maquinas
                      inyectoras que van desde las 250 hasta las 600 toneladas
                      de presión de cierre. Abastecemos a distintos sectores:
                      industria automotriz, iluminación, equipos de GNC, entre
                      otros.{" "}
                    </p>
                    <a href="#nosotros" className="theme-btn-s2">
                      Nosotros
                    </a>
                  </div>
                  <div className="col col-md-4"></div>
                </div>
              </div>
            </div>

            <div className="slide">
              <img
                src="images/slider/slide-22.jpg"
                alt="Trabajador utilizando amoladora"
                className="slider-bg"
              />
              <div className="container">
                <div className="row slide-caption slide-caption-center">
                  <div className="col col-md-8">
                    <h1 className="slide-title">
                      <span>55 años</span> de excelencia en el rubro de la
                      fundición e inyección de metales.
                    </h1>
                    <p>
                      Cada producto que creamos es el fruto de nuestra
                      experiencia, donde la tradición artesanal se fusiona con
                      tecnologías de vanguardia. Brindamos la confianza que solo
                      el tiempo puede forjar, asegurando calidad, precisión y
                      solidez.{" "}
                    </p>
                    <a href="#productos" className="theme-btn-s2">
                      Productos
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide">
              <img
                src="images/slider/slide-33.jpg"
                alt="Obrero trabajando en un horno"
                className="slider-bg"
              />
              <div className="container">
                <div className="row slide-caption slide-caption-center">
                  <div className="col col-md-8">
                    <h1 className="slide-title">
                      Nos enorgullece ofrecer un{" "}
                      <span>servicio personalizado</span> a cada uno de nuestros
                      clientes.
                    </h1>
                    <p>
                      Nuestro equipo de atención al cliente estará encantado de
                      ayudarte a encontrar la solución perfecta para tus
                      necesidades. Ya sea que estés buscando piezas estándar o
                      necesites un diseño personalizado, estamos aquí para
                      convertirlas en realidad.{" "}
                    </p>
                    <a href="#contacto" className="theme-btn-s2">
                      Contacto
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero Slider */}

        {/* Services Section */}
        <section id="nosotros" className="services-section-s2 section-padding">
          <div className="container">
            <div className="row section-title-s6">
              <div
                data-aos="fade-up"
                className="col col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1"
              >
                <h2>
                  ¿Por qué elegir<span> Gianelli?</span>
                </h2>
                <p>
                  No comprometas la calidad de tus proyectos. Elige nuestra
                  metalúrgica y confía en nosotros para entregar piezas
                  metálicas que superen tus expectativas.
                </p>
              </div>
            </div>

            <div data-aos="fade-up" className="row services-s2-grids">
              <div className="col col-lg-4 col-xs-6">
                <div className="grid">
                  <div className="img-holder">
                    <img
                      src="images/services-s2/img-1.jpg"
                      alt="Atencion personalizada"
                      className="img img-responsive"
                    />
                  </div>
                  <div className="details ">
                    <h3>
                      <a href="/">Fundada en 196X</a>
                    </h3>
                    <p>
                      Avalados por mas de 50 años desde nuestros inicios en el rubro, somos una opcion de calidad que ofrece la confianza que solo se puede ganar con la experiencia ganada en el tiempo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-xs-6">
                <div className="grid">
                  <div className="img-holder">
                    <img
                      src="images/services-s2/img-2.jpg"
                      alt="Servicios, Equipamiento"
                      className="img img-responsive"
                    />
                  </div>
                  <div className="details ">
                    <h3>
                      <a href="/">Equipamiento</a>
                    </h3>
                    <p>
                      Contamos con 9 máquinas inyectoras de cámara fría que van
                      desde las 250 hasta las 600 toneladas de presión de
                      cierre: 250 tn, 280 tn, 300 tn, 350tn, 400 tn, 450 tn y
                      600 tn.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-xs-6">
                <div className="grid">
                  <div className="img-holder">
                    <img
                      src="images/services-s2/img-3.jpg"
                      alt="Lingotes de hierro"
                      className="img img-responsive"
                    />
                  </div>
                  <div className="details ">
                    <h3>
                      <a href="/">Productos y servicios</a>
                    </h3>
                    <p>
                      Fabricamos piezas de aluminio hasta 2kg
                      aprox. Poseemos un sector de matricería,
                      para reparación y mantenimiento de moldes.
                      Hacemos trabajos simples de pulido, perforado, roscado y granallado.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Section */}

        {/* How we work */}
        <section className="how-we-work section-padding">
          <div className="container">
            <div className="row section-title-s6">
              <div
                data-aos="fade-left"
                className="col col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1"
              >
                <h2>
                  ¿Cómo es nuestro <span>trabajo?</span>
                </h2>
                <p>
                  La experiencia es clave para garantizar resultados exitosos
                  basados en tres conceptos primarios.
                </p>
              </div>
            </div>

            <div className="row content">
              <div className="col col-md-4">
                <div data-aos="zoom-out" className="grid ">
                  <span className="icon">
                    <i className="fi flaticon-strategy"></i>
                  </span>
                  <h3>Planeamiento</h3>
                  <p>
                    Nuestro equipo de expertos analiza cuidadosamente cada
                    proyecto, identificando las necesidades y objetivos
                    específicos de nuestros clientes. A través de un enfoque
                    detallado y preciso, planificamos cada paso del proceso de
                    fabricación, asegurando una ejecución sin contratiempos.
                  </p>
                </div>
              </div>
              <div className="col col-md-4">
                <div data-aos="zoom-out" className="grid ">
                  <span className="icon">
                    <i className="fi flaticon-file-1"></i>
                  </span>
                  <h3>Producción</h3>
                  <p>
                    Nuestro personal altamente utiliza técnicas avanzadas,
                    desarrolladas durante años para fabricar piezas metálicas de
                    calidad. Con un riguroso control de calidad en cada paso,
                    nos aseguramos de entregar productos que superen las
                    expectativas de nuestros clientes.
                  </p>
                </div>
              </div>
              <div className="col col-md-4">
                <div data-aos="zoom-out" className="grid ">
                  <span className="icon">
                    <i className="fi flaticon-megaphone"></i>
                  </span>
                  <h3>Compromiso</h3>
                  <p>
                    Nos dedicamos a establecer relaciones sólidas y duraderas,
                    basadas en la confianza y el respeto mutuo. Trabajamos de la
                    mano con nuestros clientes, escuchando sus necesidades,
                    ofreciendo asesoramiento experto y adaptándonos a sus
                    requerimientos específicos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End How we Work */}

        {/* Latest Projects */}
        <section id="productos" className="latest-projects-s2 section-padding">
          <div className="container">
            <div className="row section-title-s6">
              <div
                data-aos="fade-up"
                className="col col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 "
              >
                <h2>
                  Nuestros <span>productos</span>
                </h2>
                <p>
                  Cada pieza que fabricamos está cuidadosamente diseñada y
                  producida con materiales de primera categoría. Nuestro
                  compromiso con la excelencia se refleja en la resistencia,
                  precisión y durabilidad de cada producto que sale de nuestra
                  metalúrgica.
                </p>
              </div>
            </div>

            <div className="row latest-projects-s2-grids">
              <div className="col col-lg-6 left-col">
                <div data-aos="zoom-in-right" className="grid">
                  <div className="img-holder ">
                    <img
                      src="images/latest-projects-s2/img-1.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                  <div className="details">
                    <h3>
                      <a href="#">Centro de distribución</a>
                    </h3>
                    <p className="location">
                      <i className="fa fa-map-marker"></i> Ramos Mejía, Buenos
                      Aires, Argentina.
                    </p>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in-left" className="col col-lg-6 right-col">
                <div className="grid">
                  <div className="inner">
                    <div className="img-holder">
                      <img
                        src="images/latest-projects-s2/img-2.jpg"
                        alt=""
                        className="img img-responsive"
                      />
                    </div>
                    <div className="details">
                      {/* Formato para titulo en cada foto */}
                      {/* <h3> 
                        <a href="#">Pieza Falsa 123</a>
                      </h3> */}
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="inner">
                    <div className="img-holder">
                      <img
                        src="images/latest-projects-s2/img-3.jpg"
                        alt=""
                        className="img img-responsive"
                      />
                    </div>
                    <div className="details"></div>{" "}
                  </div>
                </div>
                <div className="grid">
                  <div className="inner">
                    <div className="img-holder">
                      <img
                        src="images/latest-projects-s2/img-4.jpg"
                        alt=""
                        className="img img-responsive"
                      />
                    </div>
                    <div className="details"></div>
                  </div>
                </div>
                <div className="grid">
                  <div className="inner">
                    <div className="img-holder">
                      <img
                        src="images/latest-projects-s2/img-5.jpg"
                        alt=""
                        className="img img-responsive"
                      />
                    </div>
                    <div className="details"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-projects">
              {/* Boton */}
              {/* <a href="#" className="theme-btn-s2">
                Más productos...
              </a> */}
            </div>
          </div>
        </section>
        {/* End Latest Projects */}

        {/* Contact */}
        <section id="contacto" className="contact-section">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.0795214358436!2d-58.5506311!3d-34.6526946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc876ef72dead%3A0x24089ed5aea941c7!2sADA%2C%20Pueyrred%C3%B3n%201521%2C%20B1704%20Ramos%20Mej%C3%ADa%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1699889634969!5m2!1ses-419!2sar"
            // width="600"
            // height="450"
            className="gMap"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="container">
            <div className="formContainer col col-md-5 col-md-offset-7 col-sm-6 col-sm-offset-6">
              <div className="contact-form">
                <h3>Contacto</h3>
                <ul>
                  <li>
                    <i className="fa fa-home"></i>Pueyrredón 1521, Ramos Mejía,
                    Buenos Aires.{" "}
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>011 4658-2160
                  </li>
                </ul>
                <h4>Email</h4>
                <form
                  className="form contact-validation-active"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <div>
                    <input
                      type="text"
                      id="name"
                      name="user_name"
                      className="form-control"
                      placeholder="Nombre completo"                      
                    />
                  </div>                  
                  <div>
                    <input
                      type="email"
                      id="mail"
                      name="user_mail"
                      className="form-control"
                      placeholder="E-mail"
                      
                    />
                  </div>                  
                  <div>
                    <textarea
                      name="message"
                      id="msg"
                      className="form-control"
                      placeholder="Mensaje"
                      
                    ></textarea>
                  </div>
                  <div className="submit">
                    <button type="submit">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact */}

        {/* Footer */}
        <footer className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col col-lg-6 col-md-5 col-xs-3">
                <div className="widget about-widget centerLogo">
                  <h3>
                    <img
                      src="images/logo-2.png"
                      alt="Logo Gianelli SA, Metalurgica, Zona Oeste"
                      className="logoFooter"
                    />
                  </h3>
                </div>
              </div>

              <div className="col col-lg-6 col-md-7 col-xs-9">
                <div className="widget social-media-widget">
                  <p>
                    Gracias por visitar nuestra página. Estamos ansiosos por ser
                    tu socio confiable en tus proyectos ¡No dudes en
                    contactarnos para cualquier consulta o pedido! ¡Hasta
                    pronto!
                  </p>
                  <ul className="social-links">
                    <li>
                      <a
                        target="blank"
                        href="https://www.google.com/maps/place/Gianelli/@-34.6526985,-58.5532383,17z/data=!3m1!4b1!4m6!3m5!1s0x95bcc876f91790c5:0xf316c19d1d3e11c4!8m2!3d-34.6527029!4d-58.550658!16s%2Fg%2F1thkswwg?entry=ttu"
                      >
                        <i className="fa fa-google"></i>
                      </a>
                    </li>
                    <li>
                      <a target="blank" href="https://wa.me/1161948452">
                        <i className="fa fa-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:produccion@gianellisa.com.ar">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* End Footer */}
        {/* Sticky banner */}
        <div className="stickyBanner">
          <h5>
            <i className="fa fa-phone"></i>{" "}
            <a href="tel:4658-2160">011 4658-2160</a>
          </h5>
          <h5>
            <a href="mailto:produccion@gianellisa.com.ar">
              <i className="fa fa-envelope"></i> produccion@gianellisa.com.ar
            </a>
          </h5>
        </div>
        {/* End sticky banner */}
      </div>
      {/* End Wrapper */}
    </div>
  );
}

export default App;
